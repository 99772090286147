<template>
  <div class="scroll-pagination-observer" />
</template>

<script>
export default {
  name: 'ScrollPaginationObserver',
  data() {
    return {
      intersectionObserver: null
    }
  },
  mounted() {
    this.intersectionObserver = new IntersectionObserver(([el]) => {
      if (el?.isIntersecting) {
        this.$emit('loadMore')
      }
    })

    this.intersectionObserver.observe(this.$el)
  },
  destroyed() {
    this.intersectionObserver.disconnect()
  }
}
</script>

<style>
.scroll-pagination-observer {
  height: 20px;
  margin-top: 80px;
}
</style>
