<template>
  <div class="view-offer-onboarding">
    <span class="title">{{ currentStepShortTitle }}</span>

    <v-progress-circular
      v-if="loading"
      class="loader-main"
      size="50"
      width="5"
      indeterminate
    />
    <template v-else>
      <v-rating
        :value="offerData.rate"
        hide-details
        readonly
        dense
        size="22"
        color="var(--rating-star-color)"
        background-color="var(--secondary-border-color)"
        class="rating"
      />

      <div class="heard-back-and-offer">
        <div v-if="offerData.hasHeardBack !== null" class="heard-back">
          <img :src="offerData.hasHeardBack ? '/img/happy-face.svg' : '/img/angry-face.svg'" alt="">
          <span>Heard back</span>
        </div>
        <div v-if="offerData.hasReceivedOffer !== null" class="offer">
          <img :src="offerData.hasReceivedOffer ? '/img/happy-face.svg' : '/img/angry-face.svg'" alt="">
          <span>Received an offer</span>
        </div>
      </div>

      <div class="onboarding-and-offer">
        <div class="onboarding">
          <img :src="`/img/${onboardingEmotions[offerData.howEasyOnboarding].img}`" alt="">
          <span>Onboarding was</span>
          <span class="emotion-title">{{ onboardingEmotions[offerData.howEasyOnboarding].title }}</span>
        </div>
        <div class="offer">
          <img :src="`/img/${offerEmotions[offerData.howHappyOffer].img}`" alt="">
          <span>Offer was</span>
          <span class="emotion-title">{{ offerEmotions[offerData.howHappyOffer].title }}</span>
        </div>
      </div>

      <p class="description">
        {{ offerData.description }}
      </p>
    </template>
  </div>
</template>

<script>
import api from '@/api'

export default {
  name: 'ViewOfferOnboarding',
  props: {
    currentStepShortTitle: {
      type: String,
      required: true
    },
    ticketId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      offerData: {
        description: '',
        hasHeardBack: false,
        hasReceivedOffer: false,
        howEasyOnboarding: 1,
        howHappyOffer: 1,
        rate: 0
      },
      onboardingEmotions: [
        {
          img: 'happy-face.svg',
          title: 'easy'
        },
        {
          img: 'neutral-face.svg',
          title: 'neutral'
        },
        {
          img: 'angry-face.svg',
          title: 'hard'
        }
      ],
      offerEmotions: [
        {
          img: 'happy-face.svg',
          title: 'very friendly'
        },
        {
          img: 'neutral-face.svg',
          title: 'neutral'
        },
        {
          img: 'angry-face.svg',
          title: 'no'
        }
      ]
    }
  },
  watch: {
    ticketId() {
      this.fetchOfferReview()
    }
  },
  created() {
    this.fetchOfferReview()
  },
  methods: {
    async fetchOfferReview() {
      this.loading = true
      const { data } = await api.users.getOfferReview(this.ticketId)
      this.loading = false

      if (data) this.offerData = data
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.view-offer-onboarding {
  .title {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-sub);
    text-transform: capitalize;
  }

  .rating {
    margin-left: -4px;
    margin-bottom: 20px;
  }

  .onboarding-and-offer,
  .heard-back-and-offer {
    display: flex;
    gap: 50px;
    margin-bottom: 20px;

    @include breakpoint-reverse(small) {
      flex-direction: column;
      gap: 10px;
    }

    .onboarding,
    .heard-back,
    .offer {
      display: flex;
      gap: 10px;
    }

    .emotion-title {
      font-weight: var(--font-weight-bold);
    }
  }

  .description {
    margin-top: 40px;
  }
}
</style>
