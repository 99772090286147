<template>
  <v-dialog
    v-model="isOpen"
    transition="slide-y-transition"
    max-width="638"
    content-class="review-view-modal"
    overlay-color="var(--overlay-bg-color)"
    overlay-opacity="1"
    @click:outside="[$emit('update:isReviewsViewModalOpen', false), currentStep = 1]"
  >
    <div class="header-wrapper">
      <div class="top-banner">
        <div class="company-logo-title">
          <img
            v-if="review.logo"
            :src="`/img/companies/${review.logo}`"
            height="50"
            width="50"
            :alt="review.company"
          >
          <img
            v-else
            src="/img/companies/default-logo.png"
            height="50"
            width="50"
            alt=""
          >
          <span class="modal-title">{{ review.company }}</span>
        </div>
        <div class="job-title">
          <span>{{ review.jobTitle }}</span>
        </div>
        <div class="reviewer-info">
          <div v-if="review.username" class="review-by">
            <span class="label">Reviewed by: </span>
            <span class="user-name">{{ review.username }}</span>
          </div>
          <div class="review-date">
            {{ formatISOdate(review.createDate) }}
          </div>
        </div>
        <button class="close-button" @click="[$emit('update:isReviewsViewModalOpen', false), currentStep = 1]">
          <img src="/img/icons/plus-black.svg" alt="">
        </button>
      </div>
      <ul class="breadcrumbs">
        <li
          v-for="breadcrumb in breadcrumbs"
          :key="breadcrumb.id"
          class="breadcrumb-item"
          @click="currentStep = breadcrumb.id"
        >
          <span class="breadcrumb-item-title" :class="{ 'active': currentStep === breadcrumb.id }">
            {{ breadcrumb.title }}
          </span>
        </li>
      </ul>
    </div>
    <div class="modal-main">
      <transition name="slide" enter-active-class="animated-transition" :enter-class="transitionEnterClass">
        <keep-alive>
          <component
            :is="getActiveComponent"
            :current-step-short-title="currentStepShortTitle"
            :ticket-id="review.ticketId"
          />
        </keep-alive>
      </transition>
    </div>
    <div class="modal-footer">
      <button class="report-abuse-btn" @click="sendReport">
        Report abuse
      </button>
    </div>
  </v-dialog>
</template>

<script>
import { formatISOdate } from '@/services/dateService'
import ViewApplicationExperience from '@/components/ViewApplicationExperience'
import ViewScreeningAndInterviews from '@/components/ViewScreeningAndInterviews'
import ViewOfferOnboarding from '@/components/ViewOfferOnboarding'

export default {
  name: 'ReviewsViewModal',
  components: {
    ViewApplicationExperience,
    ViewScreeningAndInterviews,
    ViewOfferOnboarding
  },
  props: {
    review: {
      type: Object,
      required: true
    },
    isReviewsViewModalOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isOpen: false,
      breadcrumbs: [
        {
          id: 1,
          title: 'Application Experience',
          shortTitle: 'application',
          component: 'ViewApplicationExperience'
        },
        {
          id: 2,
          title: 'Screening and Interviews',
          shortTitle: 'screening',
          component: 'ViewScreeningAndInterviews'
        },
        {
          id: 3,
          title: 'Offer & Onboarding',
          shortTitle: 'offer',
          component: 'ViewOfferOnboarding'
        }
      ],
      currentStep: 1,
      transitionEnterClass: '',
      currentStepShortTitle: ''
    }
  },
  computed: {
    getActiveComponent() {
      const activeBreadcrumb = this.breadcrumbs.find(breadcrumb => breadcrumb.id === this.currentStep)
      this.setShortTitle(activeBreadcrumb.shortTitle)
      return activeBreadcrumb.component
    }
  },
  watch: {
    isReviewsViewModalOpen(value) {
      this.isOpen = value
    },
    currentStep(newVal, oldVal) {
      this.transitionEnterClass = newVal > oldVal ? 'slide-in-right' : 'slide-in-left'
    }
  },
  methods: {
    formatISOdate,
    setShortTitle(title) {
      this.currentStepShortTitle = title
    },
    nextStep() {
      this.currentStep += 1
    },
    sendReport() {
      this.$gtag.event('event', { value: `abuse report from ${this.review}` })
      alert('Report sent')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.loader-main {
  margin: auto;
}

.v-dialog__content::v-deep .review-view-modal {
  @include default-modal-wrapper;
}

.header-wrapper {
  position: sticky;
  top: 0;
  z-index: 5;
}

.top-banner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top-left-radius: var(--border-radius-default);
  border-top-right-radius: var(--border-radius-default);
  padding: 15px 25px 0;
  background-color: var(--background-secondary-color);

  @include breakpoint-reverse(small) {
    padding: 5px 12px;
  }

  .company-logo-title {
    display: flex;
    align-items: center;

    & > img {
      object-fit: contain;
    }
  }

  .job-title {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-lg);
  }

  .reviewer-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0;

    .user-name {
      font-weight: var(--font-weight-bold);
    }
  }
}

.modal-title {
  margin-left: 15px;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-xxlg);
  color: var(--text-color);
}

.close-button {
  position: absolute;
  right: 15px;
  width: 24px;
  height: 24px;
  transform: rotate(45deg);
}

@include review-modal-breadcrumbs;

.modal-main {
  @include default-modal-main;

  position: relative;
  min-height: 500px;
  padding-top: 28px;
  font-weight: var(--font-weight-light);

  @include breakpoint-reverse(small) {
    padding: 12px;
  }
}

.modal-footer {
  position: sticky;
  bottom: 0;
  z-index: 5;
  padding: 37px 25px;
  background-color: var(--background-secondary-color);

  @include breakpoint-reverse(small) {
    padding: 12px;
  }

  .report-abuse-btn {
    display: block;
    margin-left: auto;
    padding: 6px 14px;
    font-size: var(--font-size-base-sm);
    border: var(--default-border);
    border-radius: var(--border-radius-small);
  }
}
</style>
