<template>
  <div class="rate-phase-block">
    <span v-if="!noTitle" class="block-title">{{ phaseData.title }}</span>
    <v-rating
      :value="phaseData.data.rate"
      hide-details
      readonly
      dense
      size="22"
      color="var(--rating-star-color)"
      background-color="var(--secondary-border-color)"
      class="rating"
    />

    <div v-if="phaseData.data.hasHeardBack !== null" class="heard-back">
      <img :src="phaseData.data.hasHeardBack ? '/img/happy-face.svg' : '/img/angry-face.svg'" alt="">
      <span>Heard back</span>
    </div>

    <span class="questions-title">
      {{ `${phaseData.title} questions` }}
    </span>

    <div class="questions-list">
      <div v-for="(question, i) in phaseData.data.questionList" :key="i" class="question">
        <span v-if="question.text.trim()">
          {{ `${i+1}. ${question.text}` }}
        </span>
      </div>
    </div>

    <p class="description">
      {{ phaseData.data.description }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'ViewRatePhaseBlock',
  props: {
    phaseData: {
      type: Object,
      required: true
    },
    currentStepShortTitle: {
      type: String,
      required: true
    },
    noTitle: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.rate-phase-block {
  .block-title {
    display: block;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-sub);
  }

  .rating {
    margin-left: -4px;
    margin-bottom: 20px;
  }

  .heard-back {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }

  .questions-title {
    display: block;
    margin: 10px 0;
    font-weight: 500;
    font-size: var(--font-size-sub);
  }

  .description {
    margin-top: 40px;
  }
}

</style>
