<template>
  <div class="view-application-experience">
    <span class="title">{{ currentStepShortTitle }}</span>

    <v-progress-circular
      v-if="loading"
      class="loader-main"
      size="50"
      width="5"
      indeterminate
    />
    <template v-else>
      <v-rating
        :value="reviewApplication.rate"
        hide-details
        readonly
        dense
        size="22"
        color="var(--rating-star-color)"
        background-color="var(--secondary-border-color)"
        class="rating"
      />

      <div v-if="reviewApplication.hasHeardBack !== null" class="heard-back">
        <img :src="reviewApplication.hasHeardBack ? '/img/happy-face.svg' : '/img/angry-face.svg'" alt="">
        <span>Heard back</span>
      </div>

      <p class="description">
        {{ reviewApplication.description }}
      </p>
    </template>
  </div>
</template>

<script>
import api from '@/api'

export default {
  name: 'ViewApplicationExperience',
  props: {
    currentStepShortTitle: {
      type: String,
      required: true
    },
    ticketId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      reviewApplication: {
        description: '',
        hasHeardBack: false,
        rate: 0
      }
    }
  },
  watch: {
    ticketId() {
      this.fetchApplicationReview()
    }
  },
  created() {
    this.fetchApplicationReview()
  },
  methods: {
    async fetchApplicationReview() {
      this.loading = true
      const { data } = await api.users.getApplicationReview(this.ticketId)
      this.loading = false
      if (data) this.reviewApplication = data
    }
  }
}
</script>

<style lang="scss" scoped>
.view-application-experience {
  .title {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-sub);
    text-transform: capitalize;
  }

  .rating {
    margin-left: -4px;
    margin-bottom: 20px;
  }

  .heard-back {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }

  .description {
    margin-top: 40px;
  }
}
</style>
