<template>
  <div class="view-screening-and-interviews">
    <v-progress-circular
      v-if="loading"
      class="loader-main"
      size="50"
      width="5"
      indeterminate
    />
    <template v-else>
      <ViewRatePhaseBlock
        :phase-data.sync="screeningAndInterviews.screening"
        :current-step-short-title="screeningAndInterviews.screening.title"
      />
      <v-expansion-panels
        flat
        accordion
        multiple
        tile
        class="accordion"
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="accordion-header">
            {{ screeningAndInterviews.interviewI.title }}
            <template v-slot:actions>
              <img src="/img/icons/plus-black.svg" alt="">
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ViewRatePhaseBlock
              no-title
              :phase-data="screeningAndInterviews.interviewI"
              :current-step-short-title="screeningAndInterviews.interviewI.title"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="accordion-header">
            {{ screeningAndInterviews.interviewII.title }}
            <template v-slot:actions>
              <img src="/img/icons/plus-black.svg" alt="">
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ViewRatePhaseBlock
              no-title
              :phase-data="screeningAndInterviews.interviewII"
              :current-step-short-title="screeningAndInterviews.interviewII.title"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </div>
</template>

<script>
import api from '@/api'
import ViewRatePhaseBlock from './ViewRatePhaseBlock'

export default {
  name: 'ViewScreeningAndInterviews',
  components: {
    ViewRatePhaseBlock
  },
  props: {
    ticketId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      screeningAndInterviews: {
        screening: {
          id: 1,
          title: 'Screening',
          data: {
            description: '',
            difficultyOfFriendly: 0,
            difficultyOfInterview: 0,
            hasHeardBack: null,
            questionList: [
              {
                text: ''
              }
            ],
            rate: 0,
            stage: 'Screening'
          }
        },
        interviewI: {
          id: 2,
          title: 'Interview I',
          data: {
            description: '',
            difficultyOfFriendly: 0,
            difficultyOfInterview: 0,
            hasHeardBack: null,
            questionList: [
              {
                text: ''
              }
            ],
            rate: 0,
            stage: 'Interview I'
          }
        },
        interviewII: {
          id: 3,
          title: 'Interview II',
          data: {
            description: '',
            difficultyOfFriendly: 0,
            difficultyOfInterview: 0,
            hasHeardBack: null,
            questionList: [
              {
                text: ''
              }
            ],
            rate: 0,
            stage: 'Interview II'
          }
        }
      }
    }
  },
  watch: {
    ticketId() {
      this.fetchScreeningInterviewReview()
    }
  },
  created() {
    this.fetchScreeningInterviewReview()
  },
  methods: {
    async fetchScreeningInterviewReview() {
      this.loading = true
      const { data } = await api.users.getInterviewReview(this.ticketId)
      this.loading = false

      if (data) {
        Object.values(this.screeningAndInterviews).forEach(block => {
          block.data = data.find(item => item.stage === block.data.stage)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.view-screening-and-interviews {
  .accordion {
    position: absolute;
    margin-top: 27px;
    margin-left: -25px;
  }

  .accordion-header {
    border-top: 1px solid var(--secondary-border-color);
    padding: 25px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-sub);
  }
}
</style>
