<template>
  <div class="review-card" @click="$emit('openReviewRateModal')">
    <div class="card-header">
      <div class="logo-and-name">
        <img
          v-if="review.logo"
          :src="`/img/companies/${review.logo}`"
          :alt="review.company"
          class="company-img"
        >
        <img
          v-else
          src="/img/companies/default-logo.png"
          alt=""
          class="company-img"
        >
        <div class="company-and-job">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="company-name"
                v-on="on"
              >
                {{ review.company }}
              </span>
            </template>
            <span>{{ review.company }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="job-title"
                v-on="on"
              >
                {{ review.jobTitle }}
              </span>
            </template>
            <span>{{ review.jobTitle }}</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div v-for="(phase, i) in phasesRates" :key="i" class="phase-rate">
        <span>{{ phase.rate || 0 }}</span>
        <v-icon size="18" color="var(--text-color)">
          mdi-star
        </v-icon>
        <span>{{ phase.title }}</span>
      </div>
    </div>
    <div class="card-footer">
      View more details of this review
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndividualReviewCard',
  props: {
    review: {
      type: Object,
      required: true
    }
  },
  computed: {
    phasesRates() {
      // TODO refactor when info about companies will be clear
      const {
        rateApplication,
        rateScreening,
        rateInterviewI,
        rateInterviewII,
        rateOffer
      } = this.review

      const ratePhases = [
        {
          title: 'Application',
          rate: rateApplication
        },
        {
          title: 'Screening',
          rate: rateScreening
        },
        {
          title: 'Interview I',
          rate: rateInterviewI
        },
        {
          title: 'Interview II',
          rate: rateInterviewII
        },
        {
          title: 'Offer',
          rate: rateOffer
        }
      ]

      return ratePhases
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.review-card {
  overflow: hidden;
  border-radius: var(--border-radius-default-sm);
  width: 258px;
  box-shadow: var(--text-shadow-default);
  background-color: var(--background-secondary-color);
  cursor: pointer;

  @include breakpoint-reverse(small) {
    width: 100%;
  }

  .card-header {
    padding: 12px 12px 0;
    color: var(--text-color);

    .logo-and-name {
      display: flex;
      align-items: center;
    }

    .company-img {
      margin-right: 15px;
      width: 55px;
      height: 55px;
      object-fit: contain;
    }

    .company-and-job {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .company-name,
    .job-title {
      overflow: hidden;
      width: fit-content;
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .company-name {
      font-weight: var(--font-weight-bold);
      font-size: 32px;
    }

    .job-title {
      font-weight: var(--font-weight-light);
      font-size: var(--font-size-base-sm);
    }
  }

  .card-body {
    display: grid;
    padding: 22px 0 28px;
    background-color: var(--background-secondary-color);
    gap: 7px;

    .phase-rate {
      display: grid;
      align-items: center;
      grid-template-columns: 22px 16px auto;
      padding: 2px 24px;
      font-size: var(--font-size-base-sm);
      gap: 10px;

      &:nth-child(odd) {
        background-color: var(--background-highlight-color);
      }

      &:nth-child(even) {
        background-color: var(--background-tertiary-color);
      }
    }
  }

  .card-footer {
    padding: 22px 22px 32px;
    text-align: center;
    color: var(--contrast-text-color);
    background-color: var(--background-primary-color);
  }
}
</style>
